import { createRouter, createWebHistory } from 'vue-router'
import { getApp } from '@/main'
import store from '@/store'

// API [R2D2]
import * as R2d2API from '@/api/r2d2'
// API [CrowdSec]
import { listDecisions } from '@/api/crowd-sec'
// API [LDAP Groups]
import { getAdGroup, getLdapGroup } from '@/api/ldap-groups'
// API [LDAP Users]
import { getAdUser, getLdapUser } from '@/api/ldap-users'
// API [Development statistics]
import * as devStatisticsAPI from '@/api/development-statistics/statistics'
import * as devStatisticsProjectsAPI from '@/api/development-statistics/projects'
import * as devStatisticsAuthorsAPI from '@/api/development-statistics/authors'
// API [Message of the Day]
import * as MotdAPI from '@/api/motd'

// API [Administration]
import { getUsers, getUser } from '@/api/users'

// Utils
import { StatusTypes as ProjectStatusTypes } from '@/utils/development-statistics/Projects'

// Layouts
import PageWithNavBarAndFooterLayout from '@/layouts/PageWithNavBarAndFooterLayout.vue'

// Pages
const HomePage = () => import('@/pages/HomePage.vue')
const UnauthorizedPage = () => import('@/pages/UnauthorizedPage.vue')
const PageNotFoundPage = () => import('@/pages/PageNotFoundPage.vue')
// R2D2 Pages
const R2d2 = () => import('@/pages/r2d2/R2d2.vue')
const ListR2D2Databases = () => import('@/pages/r2d2/ListR2D2Databases.vue')
// CrowdSec pages
const CrowdSec = () => import('@/pages/crowd-sec/CrowdSec.vue')

// Ldap page components
const LdapSearch = () => import('@/pages/ldap-search/LdapSearch.vue')
const LDAPGroup = () => import('@/pages/ldap-search/LdapGroup.vue')
const LDAPUser = () => import('@/pages/ldap-search/LdapUser.vue')

// Development statistics pages
const DevelopmentStatisticsHomePage = () => import('@/pages/development-statistics/HomePage.vue')
const GlobalStatisticsPage = () => import('@/pages/development-statistics/statistics/GlobalStatisticsPage.vue')
const ProjectStatisticsPage = () => import('@/pages/development-statistics/statistics/ProjectStatisticsPage.vue')
const ManagementPage = () => import('@/pages/development-statistics/management/ManagementPage.vue')
const ProjectsManagementPage = () => import('@/pages/development-statistics/management/projects/ProjectsManagementPage.vue')
const ProjectManagementPage = () => import('@/pages/development-statistics/management/projects/ProjectManagementPage.vue')
const AuthorsManagementPage = () => import('@/pages/development-statistics/management/authors/AuthorsManagementPage.vue')
const AuthorManagementPage = () => import('@/pages/development-statistics/management/authors/AuthorManagementPage.vue')
const CreateAuthorPage = () => import('@/pages/development-statistics/management/authors/CreateAuthorPage.vue')
const EditAuthorPage = () => import('@/pages/development-statistics/management/authors/EditAuthorPage.vue')
// Message of the Day pages
const MotdPage = () => import('@/pages/motd/MotdPage.vue')
const MotdVersionHistoryPage = () => import('@/pages/motd/MotdVersionHistoryPage.vue')
const MotdUserAcksPage = () => import('@/pages/motd/MotdUserAcksPage.vue')
const MotdUserSearchPage = () => import('@/pages/motd/MotdUserSearchPage.vue')
const motdUserSearchResultsPage = () => import('@/pages/motd/MotdUserSearchResultsPage.vue')
const MotdNewPage = () => import('@/pages/motd/MotdNewPage.vue')
const MotdEditPage = () => import('@/pages/motd/MotdEditPage.vue')
const MotdMessageAcksPage = () => import('@/pages/motd/MotdMessageAcksPage.vue')
const MotdMessagePage = () => import('@/pages/motd/MotdMessagePage.vue')
// Administration pages
const UsersAdministrationPage = () => import('@/pages/admin/users/UsersAdministrationPage.vue')
const UserAdministrationPage = () => import('@/pages/admin/users/UserAdministrationPage.vue')

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: PageWithNavBarAndFooterLayout,
      redirect: { name: 'HomePage', query: { active: 'true' } },
      children: [
        {
          path: '/unauthorized',
          name: 'UnauthorizedPage',
          component: UnauthorizedPage,
          meta: {
            public: true
          }
        },
        {
          path: '/',
          name: 'HomePage',
          component: HomePage
        },
        {
          path: '/r2d2/',
          name: 'R2d2',
          component: R2d2,
          meta: {
            'porg-auth': {
              roles: ['user', 'r2d2']
            }
          },
          props: route => ({
            projects: route.meta.projects
          }),
          async beforeEnter (to, from, next) {
            to.meta.projects = await R2d2API.listAvailableDatabaseTypes()
            next()
          }
        },
        {
          path: '/r2d2/:id',
          name: 'ListR2D2Databases',
          component: ListR2D2Databases,
          meta: {
            'porg-auth': {
              roles: ['user', 'r2d2']
            }
          },
          props: route => ({
            availableDatabases: route.meta.availableDatabases,
            runningDatabases: route.meta.runningDatabases,
            project: route.meta.project,
            id: Number(route.params.id)
          }),
          async beforeEnter (to, from, next) {
            const [project, availableDatabases, runningDatabases] = await Promise.all([
              R2d2API.getProject(to.params.id),
              R2d2API.listAvailableDatabases(to.params.id),
              R2d2API.listRunningDatabases(to.params.id)
            ])
            to.meta.project = project
            to.meta.availableDatabases = availableDatabases
            to.meta.runningDatabases = runningDatabases
            next()
          }
        },
        {
          path: '/crowd-sec/',
          name: 'CrowdSec',
          component: CrowdSec,
          meta: {
            'porg-auth': {
              roles: ['user', 'crowd-sec']
            }
          },
          props: route => ({
            initialDecisionsGroupedByIp: route.meta.initialDecisionsGroupedByIp
          }),
          async beforeEnter (to, from, next) {
            to.meta.initialDecisionsGroupedByIp = await listDecisions()
            next()
          }
        },
        {
          path: '/ldap/search',
          name: 'LdapSearch',
          component: LdapSearch,
          meta: {
            'porg-auth': {
              roles: ['user', 'ldap']
            }
          }
        },
        {
          path: '/ldap/ad/groups/:name',
          name: 'AdGroup',
          component: LDAPGroup,
          meta: {
            'porg-auth': {
              roles: ['user', 'ldap']
            }
          },
          props: route => ({
            group: route.meta.group
          }),
          async beforeEnter (to, from, next) {
            const group = await getAdGroup(to.params.name)
            to.meta.group = group
            next()
          }
        },
        {
          path: '/ldap/ad/users/:uid',
          name: 'AdUser',
          component: LDAPUser,
          meta: {
            'porg-auth': {
              roles: ['user', 'ldap']
            }
          },
          props: route => {
            return {
              user: route.meta.user
            }
          },
          async beforeEnter (to, from, next) {
            const user = await getAdUser(to.params.uid)
            to.meta.user = user
            next()
          }
        },
        {
          path: '/ldap/openldap/groups/:name',
          name: 'LDAPGroup',
          component: LDAPGroup,
          meta: {
            'porg-auth': {
              roles: ['user', 'ldap']
            }
          },
          props: route => ({
            group: route.meta.group
          }),
          async beforeEnter (to, from, next) {
            const group = await getLdapGroup(to.params.name)
            to.meta.group = group
            next()
          }
        },
        {
          path: '/ldap/openldap/users/:uid',
          name: 'LDAPUser',
          component: LDAPUser,
          meta: {
            'porg-auth': {
              roles: ['user', 'ldap']
            }
          },
          props: route => {
            return {
              user: route.meta.user
            }
          },
          async beforeEnter (to, from, next) {
            const user = await getLdapUser(to.params.uid)
            to.meta.user = user
            next()
          }
        },
        {
          path: '/development-statistics',
          name: 'DevelopmentStatistics',
          component: DevelopmentStatisticsHomePage,
          meta: {
            'porg-auth': {
              roles: ['user', 'development-statistics']
            }
          },
          props: route => ({
            metadata: route.meta.metadata,
            projectsList: route.meta.projectsList
          }),
          beforeEnter: async (to, from, next) => {
            const [metadata, projectsList] = await Promise.all([
              devStatisticsAPI.getMetadata(),
              devStatisticsProjectsAPI.getActiveProjects()
            ])
            to.meta.metadata = metadata
            to.meta.projectsList = projectsList
            next()
          }
        },
        {
          path: '/development-statistics/statistics',
          name: 'StatisticsPage',
          redirect: { name: 'GlobalStatisticsPage' }
        },
        {
          path: '/development-statistics/statistics/global',
          name: 'GlobalStatisticsPage',
          component: GlobalStatisticsPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'development-statistics']
            }
          },
          props: route => ({
            statistics: route.meta.statistics,
            start: route.query.start,
            end: route.query.end
          }),
          beforeEnter: async (to, from, next) => {
            const statistics = await devStatisticsAPI.getGlobalStatistics({
              start: to.query.start,
              end: to.query.end
            })
            to.meta.statistics = statistics
            next()
          }
        },
        {
          path: '/development-statistics/statistics/project/:id',
          name: 'ProjectStatisticsPage',
          component: ProjectStatisticsPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'development-statistics']
            }
          },
          props: route => ({
            project: route.meta.project,
            statistics: route.meta.statistics,
            start: route.query.start,
            end: route.query.end
          }),
          beforeEnter: async (to, from, next) => {
            const [project, statistics] = await Promise.all([
              devStatisticsProjectsAPI.getProject({ id: to.params.id }),
              devStatisticsAPI.getProjectStatistics({
                id: to.params.id,
                start: to.query.start,
                end: to.query.end
              })
            ])
            to.meta.project = project
            to.meta.statistics = statistics
            next()
          }
        },
        {
          path: '/development-statistics/manage',
          component: ManagementPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'development-statistics']
            }
          },
          children: [
            {
              path: '',
              name: 'ManagementPage',
              redirect: { name: 'ProjectsManagementPage' }
            },
            {
              path: 'projects',
              name: 'ProjectsManagementPage',
              component: ProjectsManagementPage,
              props: route => ({
                projects: route.meta.projects,
                totalItems: route.meta.totalItems,
                totalActive: route.meta.totalActive,
                totalInactive: route.meta.totalInactive,
                query: route.query.q,
                status: route.query.status,
                page: route.meta.page,
                perPage: route.meta.perPage
              }),
              beforeEnter: async (to, from, next) => {
                // Validate status from query
                if (to.query.status && !Object.values(ProjectStatusTypes).includes(to.query.status)) {
                  return next('/404')
                }

                // Default pagination items per page
                to.meta.perPage = 7
                to.meta.page = Number(to.query.page) || 1

                // Fetch projects
                const { totalItems, totalActive, totalInactive, items } = await devStatisticsProjectsAPI.getProjects({
                  search: to.query.q,
                  status: to.query.status,
                  page: to.meta.page,
                  perPage: to.meta.perPage
                })
                to.meta.projects = items
                to.meta.totalItems = totalItems
                to.meta.totalActive = totalActive
                to.meta.totalInactive = totalInactive
                next()
              }
            },
            {
              path: 'projects/:id',
              name: 'ProjectManagementPage',
              component: ProjectManagementPage,
              props: route => ({
                project: route.meta.project
              }),
              beforeEnter: async (to, from, next) => {
                const project = await devStatisticsProjectsAPI.getProject({ id: to.params.id })
                to.meta.project = project
                next()
              }
            },
            {
              path: 'authors',
              name: 'AuthorsManagementPage',
              props: route => ({
                authors: route.meta.authors,
                totalItems: route.meta.totalItems,
                query: route.query.q,
                page: route.meta.page,
                perPage: route.meta.perPage
              }),
              component: AuthorsManagementPage,
              beforeEnter: async (to, from, next) => {
                // Default pagination items per page
                to.meta.perPage = 7
                to.meta.page = Number(to.query.page) || 1

                // Fetch authors
                const { totalItems, items } = await devStatisticsAuthorsAPI.getAuthors({
                  search: to.query.q,
                  page: to.meta.page,
                  perPage: to.meta.perPage
                })
                to.meta.authors = items
                to.meta.totalItems = totalItems
                next()
              }
            },
            {
              path: 'authors/create',
              name: 'CreateAuthorPage',
              component: CreateAuthorPage
            },
            {
              path: 'authors/:id',
              name: 'AuthorManagementPage',
              component: AuthorManagementPage,
              props: route => ({
                author: route.meta.author
              }),
              beforeEnter: async (to, from, next) => {
                const author = await devStatisticsAuthorsAPI.getAuthor({ id: to.params.id })
                to.meta.author = author
                next()
              }
            },
            {
              path: 'authors/:id/edit',
              name: 'EditAuthorPage',
              component: EditAuthorPage,
              props: route => ({
                author: route.meta.author
              }),
              beforeEnter: async (to, from, next) => {
                const author = await devStatisticsAuthorsAPI.getAuthor({ id: to.params.id })
                to.meta.author = author
                next()
              }
            }
          ]
        },
        {
          path: '/motd',
          name: 'MotdPage',
          component: MotdPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'motd']
            }
          }
        },
        {
          path: '/motd/:messageId',
          name: 'MotdMessagePage',
          component: MotdMessagePage,
          meta: {
            'porg-auth': {
              roles: ['user', 'motd']
            }
          },
          props: route => {
            return {
              message: route.meta.message
            }
          },
          async beforeEnter (to, from, next) {
            const message = await MotdAPI.getMotd(to.params.messageId)
            to.meta.message = message
            next()
          }
        },
        {
          path: '/motd/:messageId/versions',
          name: 'MotdVersionHistoryPage',
          component: MotdVersionHistoryPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'motd']
            }
          },
          props: route => {
            return {
              message: route.meta.message
            }
          },
          async beforeEnter (to, from, next) {
            const message = await MotdAPI.getMotd(to.params.messageId)
            to.meta.message = message
            next()
          }
        },
        {
          path: '/motd/:messageId/acks',
          name: 'MotdMessageAcksPage',
          component: MotdMessageAcksPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'motd']
            }
          },
          props: route => {
            return {
              message: route.meta.message
            }
          },
          async beforeEnter (to, from, next) {
            const message = await MotdAPI.getMotd(to.params.messageId)
            to.meta.message = message
            next()
          }
        },
        {
          path: '/motd/:messageId/edit',
          name: 'MotdEditPage',
          component: MotdEditPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'motd']
            }
          },
          props: route => {
            return {
              message: route.meta.message
            }
          },
          async beforeEnter (to, from, next) {
            const message = await MotdAPI.getMotd(to.params.messageId)
            to.meta.message = message
            next()
          }
        },
        {
          path: '/motd/new',
          name: 'MotdNewPage',
          component: MotdNewPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'motd']
            }
          }
        },
        {
          path: '/motd/acks/search',
          name: 'MotdUserSearchPage',
          component: MotdUserSearchPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'motd']
            }
          }
        },
        {
          path: '/motd/acks/search/results',
          name: 'MotdUserSearchResultsPage',
          component: motdUserSearchResultsPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'motd']
            }
          },
          props: route => ({
            query: route.query.query,
            users: route.meta.users
          }),
          async beforeEnter (to, from, next) {
            const users = await MotdAPI.searchMotdUsers(to.query.query)
            to.meta.users = users
            next()
          }
        },
        {
          path: '/motd/acks/:uid',
          name: 'MotdUserAcksPage',
          component: MotdUserAcksPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'motd']
            }
          },
          props: route => {
            return {
              user: route.meta.user,
              acks: route.meta.acks
            }
          },
          async beforeEnter (to, from, next) {
            const user = await getLdapUser(to.params.uid)
            const acks = await MotdAPI.getUserAcks(to.params.uid)
            to.meta.user = user
            to.meta.acks = acks
            next()
          }
        },
        {
          path: '/admin/users',
          name: 'UsersAdministrationPage',
          component: UsersAdministrationPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'admin']
            }
          },
          props: route => ({
            users: route.meta.users,
            totalItems: route.meta.totalItems,
            query: route.query.q,
            page: route.meta.page,
            perPage: route.meta.perPage
          }),
          beforeEnter: async (to, from, next) => {
            // Default pagination items per page
            to.meta.perPage = 7
            to.meta.page = Number(to.query.page) || 1

            // Fetch users
            const { totalItems, items } = await getUsers({
              search: to.query.q,
              page: to.meta.page,
              perPage: to.meta.perPage
            })
            to.meta.users = items
            to.meta.totalItems = totalItems
            next()
          }
        },
        {
          path: '/admin/users/:username',
          name: 'UserAdministrationPage',
          component: UserAdministrationPage,
          meta: {
            'porg-auth': {
              roles: ['user', 'admin']
            }
          },
          props: route => ({
            user: route.meta.user
          }),
          beforeEnter: async (to, from, next) => {
            const user = await getUser({ username: to.params.username })
            to.meta.user = user
            next()
          }
        }
      ]
    },
    {
      path: '/logout',
      name: 'Logout'
    },
    {
      path: '/:pathMatch(.*)',
      component: PageWithNavBarAndFooterLayout,
      children: [{
        path: '',
        name: 'PageNotFound',
        component: PageNotFoundPage
      }]
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('setProgressBar', 10)

  // we want this here and not in router/index.js because we want vue-porg's
  // permission system to run before this global navigation guard
  if (from.name && to.path === from.path) {
    // don't run when only the query params change
    return next()
  }

  const app = getApp()
  if (!hasPersistedNotification()) {
    app.clearNotification()
  }

  if (to.path === '/logout' && app.$auth.isLogged()) {
    app.$auth.logout()
    return
  }

  if (!to.meta.public) {
    await store.dispatch('fetchProfile')
    store.dispatch('setProgressBar', 60)
  }

  next()
})

router.afterEach((to, from, next) => {
  store.dispatch('completeProgressBar')
})

const hasPersistedNotification = () => {
  if (!store.state.topMessage.active) {
    return false
  }
  return store.state.topMessage.persist
}

export default router
