export default Object.freeze({
  // Default user role
  user: 'user', // Enforced role
  // Administrator role (can manage users & user roles)
  admin: 'admin',
  // Has access to r2d2 service
  r2d2: 'r2d2', // Enforced role
  // Has access to crowd-sec service
  crowdSec: 'crowd-sec',
  // Has access to ldap-groups service
  ldap: 'ldap',
  // Has access to development statistics service
  developmentStatistics: 'development-statistics',
  // Has access to message of the day service
  messageOfTheDay: 'motd'
})
